import sellerRepresentation from "../assect/images/website/20.jpg";
import landlordRepresentation from "../assect/images/website/InvestorRepresentation.jpg";
import buyerRepresentation from "../assect/images/website/22.jpg";
import tenantRepresentation from "../assect/images/website/23.jpg";
import investorRepresentation from "../assect/images/website/39.jpg";
import propertyManagement from "../assect/images/website/25.jpg";
import businessServices from "../assect/images/website/26.jpg";
import financing from "../assect/images/website/27.jpg";
import development from "../assect/images/website/28.jpg";
import sustainability from "../assect/images/website/29.jpg";
import valuation from "../assect/images/website/30.jpg";
import marketInsights from "../assect/images/website/31.jpg";
import design from "../assect/images/website/32.jpg";

export const data = [
  {
    image: buyerRepresentation,
    title: "Seller Representation",
    sectionTitle: "Selling a property",
    overview: `Your property is more than just a building. It is often a childhood home, or the place where you raised your family. Other times it is an investment that is a testament to your astuteness and industriousness. Parting with it requires thoughtful consideration. We understand the emotional and psychological implications of selling property, and we approach every sale with care. Selling properties can be a complex and time-consuming process; we have the experience and knowledge to navigate you through the intricacies of the market. With a deep understanding of the unique challenges that may arise, we help our clients maximize their return on investment while ensuring a smooth and hassle-free sale. `,
    description: `Your property isn't just listed; it's presented to inspire, attracting the perfect buyers for the legacy you've built. We showcase the existing use and the potential of your space to get you the most for your property. We excel in strategic property valuation, targeted marketing, and negotiations.`,
    keyFeatures: [
      "Strategic Valuation: Comprehensive property valuation for optimal pricing.Strategic Valuation: Comprehensive property valuation for optimal pricing.",
      "Inspired Marketing: Creative and targeted marketing to attract the right buyer.",
      "Negotiation Excellence: Skillful negotiation to achieve the best price and terms.",
    ],
  },
  {
    image: sellerRepresentation,
    title: "Landlord Representation",
    sectionTitle: "Lease out a property",
    overview: `Your property is not just a building; it's a cornerstone of your financial portfolio. You are personally and financially vested in how your property is used and maintained. As a result, finding the right tenant to occupy your space is paramount. We are devoted to attracting tenants that will be good stewards of your property and getting you the highest financial return.`,
    description: `Your property isn't just listed; it's presented to inspire, attracting the perfect steward for the legacy you've built. We showcase the existing use and the potential of your space to get you the most for your property. We excel in strategic property valuation, targeted marketing, and negotiations. `,
    keyFeatures: [
      "Targeted Marketing: Strategic marketing to attract suitable and committed tenants.",
      "Lease Management: Efficient management and documentation of lease agreements.",
      "Value Optimization: Strategies to maximize the value of your property.",
    ],
  },
  {
    image: tenantRepresentation,
    title: "Property Management",
    sectionTitle: "Property Management",
    overview: `Whether you’re looking to free up your time of the day-to-day grind of managing a single unit or a portfolio, we are here to help. Our perspective is real estate portfolio management is about orchestrating strategies that optimize returns and ensure sustained growth; and property management is about crafting experiences for both owners and tenants that leave a lasting impression.`,
    description: `Our property management services have you covered. We create customized strategies that boost your property's value and keep your tenants happy. We handle the routine tasks, stay on top of maintenance, and provide accurate reporting to free you to focus on your goals.`,
    keyFeatures: [
      `Customized Portfolio Strategies: We'll collaborate with you to develop personalized plans that align with your objectives.`,
      `Proactive Asset Management: Rely on us to implement proactive measures that safeguard the performance and resilience of your portfolio or property.`,
      `Investor Satisfaction: We prioritize your satisfaction and strive to deliver results that exceed your expectations, ensuring a fruitful and rewarding partnership.`,
    ],
  },
  {
    image: landlordRepresentation,
    title: "Investor Representation",
    sectionTitle: "Invest in a Property",
    overview: `We understand that investors have unique goals and a satisfactory return on investment differ for each investor. As you scan the real estate landscape for opportunities to grow your portfolio and make a lasting impact in the community; partner with us to go beyond listed properties available to the competition. Whether you're interested in diversifying your current portfolio, development, or income-generating assets; were here to help you achieve your goals.`,
    description: `We get you to the opportunity before it goes on the market. Finding niches and opportunities that does not require you to erode profit margins by entering bidding wars is where we shine. We go beyond transactions; we're about creating strategies that maximize your returns and minimize your risks. From thorough property evaluations to skillful negotiations, we're your partners every step of the way.`,
    keyFeatures: [
      "Strategic Property Evaluation: Comprehensive analysis tailored to your investment criteria",
      "Expert Negotiation: Skillful bargaining to secure favorable terms and maximize profitability.",
      "Market Insights: In-depth research and analysis to support well-informed investment decisions.",
    ],
  },
  {
    image: investorRepresentation,
    title: "Buyer Representationn",
    sectionTitle: "Buy a property ",
    overview: `Your vision reaches beyond bricks and mortar; it's about finding the perfect space. We recognize the profound significance of this transaction and approach your needs with diligence, and respect. Your needs are unique, and we pride ourselves in understanding your demands and working with you to acquire the best property for you.`,
    description: `We don't just facilitate transactions. We partner and guide you through the journey of acquiring your ideal space. we craft tailored solutions to match your vision. From exhaustive property searches to skillful negotiations, we stand with you in securing the idea space for you to call a home or invest in. `,
    keyFeatures: [
      "Property Search: A comprehensive property search tailored to your specific requirements.",
      "Strategic Negotiations: Expert negotiation to obtain favorable terms and price.",
      "Market Expertise: In-depth market analysis to enable informed decisions.",
    ],
  },
  {
    image: financing,
    title: "Tenant Representation",
    sectionTitle: "Tenant Representation",
    overview: `Where you live is not a simple matter of sticks and stones. It’s about pride, safety, community, and growth. We realize the value of meeting your current and future needs while remaining within budget. Moreover, since your circumstance is unique, we work with you to discover your needs and find the ideal property for you. With a keen insight of the markets, and budgeting, we tailor our search to find a place where you can thrive. From negotiations to legal considerations, we ensure your lease is in harmony with your needs.
      `,
    description: `On your quest to find the perfect property, let us navigate you through the journey of acquiring your ideal space. We go beyond just facilitating a transaction by guiding you through every step of the process. From discovering your needs, through negotiations, to moving in; we work tirelessly to ensure the process is hassle free and efficient. We are your partner in securing the ideal space for you.`,
    keyFeatures: [
      "Tailored Property Search: Finding a space that aligns perfectly with your needs.",
      "Lease Negotiation: Expertise in negotiating leases that suit your budget.",
      "Market Insights: In-depth knowledge of local market conditions",
    ],
  },
  {
    image: financing,
    title: "Church Owned Business",
    sectionTitle: "Church Owned Business",
    overview: `As a religious organization, at times opportunities to impact your community through commerce is the proper fit for your ministry. We get it; selling, buying, starting, and operating a business is a big deal. We want to support you as a leader in the journey, whether you're passing on your legacy or pursuing a new vision, we want to ensure your success.
      `,
    description: `As the nuances of your calling become apparent, we want to be there for you. The time might be right to invest in purchasing or building a company that mirrors your mission, we are here to engage with you in the undertaking with expert guidance. Perhaps the time has come to part with a business, we are here to navigate you through that process.`,
    keyFeatures: [
      "Buy a Business: We'll search far and wide to help you acquire the business that is the perfect fit for your vision, goals, resources, and financial return requirements. Every aspect from negotiating to a smooth transition into ownership is done with utmost care to ensure your success.",
      "Sell a Business: Craft compelling stories and present financials that showcase the value and importance of your business to attract the perfect buyer while protecting your privacy throughout the selling process.",
      "Management Consulting: We believe that you should dream big, plan wisely and execute judiciously. The entrepreneurial journey need not be lonely; we are here for you at every crucial step: startup, finance, marketing, operations, planning, growing, recruiting, and stabilizing and even exit.",
    ],
  },
];

export const data2 = [
  {
    image: financing,
    title: "Financing Church Property",
    sectionTitle: "Financing Church Property",
    overview: `Finance should not hinder ministry. As leaders you are tasked with endless responsibilities and navigating through the mortgage maze can be a daunting task.  
      Our commitment is to unlock the financial pathways that transform your real estate aspirations into reality. Beyond mere funding solutions, we help craft a strategic approach to financing your desired property, ensuring that every step aligns with your long-term goals. We are your partner in navigating the complex landscape of funding options, so your congregation can thrive and flourish.      
      `,
    description: `We understand that securing the right financing is crucial for bringing your church real estate projects to fruition. Once we understand your vision, mission, current financial standing, and financial abilities, we work hand in hand to develop a strategy to acquire the funding needed to purchase the perfect space for your ministry.`,
    keyFeatures: [
      "Funding Strategies: Tailored financing plans to match the unique needs of your project.",
      "Expert Guidance: Navigate through various funding sources.",
      "Optimized Terms: Selection of favorable financing terms that align with your long-term goals and the growth of your ministry",
    ],
  },
  {
    image: development,
    title: "Development Of Church Property",
    sectionTitle: "Development Of Church Property",
    overview: `As a visionary you see what others miss. Sometimes to make your vision a reality the best course of action is through development. Our development service partners with you to ensure masterful development of the space required to house your ministry.  From conceptualization to completion, we guide you through every stage of the development process, ensuring that your church property becomes a testament to your community's mission.  
      `,
    description: `Whether your vision is new construction or revitalizing an existing property, our team brings expertise in project management, design, and execution. From ideation to occupancy, we will work tirelessly to transform your visions into reality.`,
    keyFeatures: [
      "Project Management: Oversee every aspect of development for seamless execution.",
      "Creative Design: Collaborate with our experts to design spaces that resonate with your mission.",
      "Execution: Efficient project timelines to bring your vision to life without delays.",
    ],
  },
  {
    image: sustainability,
    title: "Sustainable Church Property",
    sectionTitle: "Sustainable Church Property",
    overview: `As a leader you think about your impact now and the footprint you leave behind. 
      We believe in creating spaces that not only stand the test of time but also contribute to the well-being of our planet. Our commitment to sustainability transcends eco-friendly designs; it encompasses a holistic approach to building practices that mirror your ministries’ values as stewards of the Earth we inhabit.        
      `,
    description: `We integrate eco-friendly practices into every aspect of church real estate. From energy-efficient designs to sustainable materials, to waste management, we help you create a space that forges a legacy that aligns your values, and the well-being of the planet.`,
    keyFeatures: [
      "Green Design: Implement sustainable and energy-efficient designs for long-term environmental benefits.",
      "Material Selection: Utilize eco-friendly materials that contribute to a sustainable building lifecycle.",
      "Cost-Efficient Solutions: Balance sustainability with cost-effectiveness for a responsible investment.",
    ],
  },
  {
    image: valuation,
    title: "Valuation of Church Property",
    sectionTitle: "Valuation of Church Property",
    overview: `We understand that every church property holds a unique story and legacy. Therefore, no number can truly capture its worth to its owner. Realizing the sensitive nature of trying to place a number on such a meaningful space, we are committed to understanding the current and potential value of the property. Whether you're deciding on a sale, acquisition, or long-term financial planning, we will get you the valuations required to make an educated decision.        
      `,
    description: `Uncover the value of your property with our Valuation Services. We investigate the current use of the property, market conditions, market trends, and intangibles to arrive at a value that you can confidently use to make an informed decision.`,
    keyFeatures: [
      "Comprehensive Valuation: Accurate valuations considering various factors influencing property worth.",
      "Market Trends Analysis: Stay informed with insights into current market conditions affecting your property's value.",
      "Financial Planning Support: Use valuation data for strategic financial planning and decision-making.",
    ],
  },
  {
    image: marketInsights,
    title: "Market Insights of Church Property",
    sectionTitle: "Market Insights of Church Property",
    overview: `In the dynamic landscape of real estate, knowledge is power. Moreover, specialized intelligence is required to answer questions about religious properties. Our commitment is to provide you with strategic insights that empower you to make well-informed decisions that align with your values and ministry.        
      `,
    description: `We believe that the best course of action is proactive market monitoring, identifying emerging opportunities, and developing strategies to mitigate potential risks. We equip you with the required insights to navigate the complexities of the market and stay ahead of the curve.`,
    keyFeatures: [
      "Proactive Market Monitoring: Stay ahead with real-time insights into market trends and developments.",
      "Strategic Opportunity Identification: Identify emerging opportunities aligned with your church's mission.",
      "Risk Mitigation Strategies: Anticipate challenges and develop strategies to mitigate potential risks.",
    ],
  },
  {
    image: design,
    title: "Design of Church Property",
    sectionTitle: "Design of Church Property",
    overview: `At Piertop, we understand that designing church property is deeply personal. Whether it's renovating an existing building or creating something entirely new, we're here to support you every step of the way. Creating spaces that feel like home, where your ministry can grow is our commitment to you. We believe that the aesthetic of your property is about capturing the essence of your ministry, and your vision.  Because we understand the profound impact that visuals have on identity, we are dedicated to bringing your vision to life with care and creativity.        
      `,
    description: `Let us undertake the design to bring your vision into fruition.  From brainstorming ideas to putting the finishing touches, we're committed to delivering a design that resonates with your goals.`,
    keyFeatures: [
      "Personalized Design Approach: We work closely with you to develop a design that captures your unique vision.",
      "Creative Solutions: We bridge your goals with innovation and creativity at every aspect of the design process, ensuring your church property stands out.",
      "Collaborative Partnership: We are dedicated to going above and beyond to create a space that exceeds your expectations and enriches your ministry.",
    ],
  },
];
