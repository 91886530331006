import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import bg3 from "../assect/images/website/8.jpg";
import hero from "../assect/images/website/10.jpg";
import image1 from "../assect/images/website/9.jpg";
import dots from "../assect/images/svg/dots.svg";
import finance from "../assect/images/icons/finance-icon.png";
import development from "../assect/images/icons/development-icon.png";
import sustainability from "../assect/images/icons/sustainability-icon.png";
import valuation from "../assect/images/icons/valuation-icon.png";
import insights from "../assect/images/icons/insights-icon.png";
import { FiHexagon } from "../assect/icons/vander";
import RoutesEnums from "../enums/routes.enums";
import { data } from "../language/RealStateBrokage.js";

export default function Features() {
  let navigate = useNavigate();

  const featureData = [
    {
      icon: finance,
      title: "Seller Representation",
      desc: "Your property is more than just a building; parting from it requires obtaining maximum return on investment and finding the right people to care for it going forward.",
    },
    {
      icon: development,
      title: "Landlord Representation",
      desc: "Who rents out your property matters; the intersection of highest return and ideal tenant is the optimal point when leasing your property.",
    },
    {
      icon: sustainability,
      title: "Property Management",
      desc: "Enjoy peace of mind knowing the meticulous day-to-day operations of your property, ensures it remains a welcoming and well-maintained space.",
    },
    {
      icon: valuation,
      title: "Investor Representation",
      desc: "Partner with us to navigate tailored real estate investment opportunities to meet your financial goals with confidence.",
    },
    {
      icon: insights,
      title: "Buyer Representation",
      desc: "Purchasing a property is more than just a transaction; it's a pivotal moment where finding the perfect unit shapes your future and the future of your loved ones.",
    },
    {
      icon: valuation,
      title: "Tenant Representation",
      desc: "The perfect space for you brings you pleasure and comfort that contribute to your overall wellbeing. Let’s connect your vision with the perfect space.",
    },
  ];

  const featureData2 = [
    {
      icon: finance,
      title: "Seller Representation",
      desc: "Your property is more than just a building; parting from it requires obtaining maximum return on investment and finding the right people to care for it going forward.",
    },
    {
      icon: development,
      title: "Landlord Representation",
      desc: "Who rents out your property matters; the intersection of highest return and ideal tenant is the optimal point when leasing your property.",
    },
    {
      icon: sustainability,
      title: "Property Management",
      desc: "Enjoy peace of mind knowing the meticulous day-to-day operations of your property, ensures it remains a welcoming and well-maintained space.",
    },
    {
      icon: valuation,
      title: "Investor Representation",
      desc: "Partner with us to navigate tailored real estate investment opportunities to meet your financial goals with confidence.",
    },
    {
      icon: insights,
      title: "Buyer Representation",
      desc: "Purchasing a property is more than just a transaction; it's a pivotal moment where finding the perfect unit shapes your future and the future of your loved ones.",
    },
    {
      icon: insights,
      title: "Tenant Representation",
      desc: "The perfect space for you brings you pleasure and comfort that contribute to your overall wellbeing. Let’s connect your vision with the perfect space.",
    },
  ];

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Services
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={hero}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                  <div className="position-absolute top-0 start-0 z-n1">
                    <img src={dots} className="avatar avatar-xl-large" alt="" />
                  </div>
                </div>

                <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={image1} className="img-fluid rounded-3" alt="" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">Meeting Your Needs</h4>
                <p className="text-muted para-desc mb-0">
                Piertop focuses on your most critical issues and opportunities: Acquisitions, Disposition, Leasing, Financing and Management. The world of real estate can be both difficult to navigate and time consuming; rely on us to steer you every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">Residential &  Commercial Brokerage</h4>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {featureData.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate(RoutesEnums.SERVICE_DETAIL, {
                      state: data[index],
                    });
                  }}
                  className="col-md-4"
                  key={index}
                >
                  <div className="position-relative features text-center mx-lg-4 px-md-1">
                    <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                      <FiHexagon className="hexagon" />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <img
                          src={item.icon}
                          width="25px"
                          height="25px"
                          alt="icon"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <Link to="" className="fw-medium title text-dark fs-5">
                        {item.title}
                      </Link>
                      <p className="text-muted mt-3 mb-0">{item.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">Real estate consulting</h4>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {featureData2.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate(RoutesEnums.SERVICE_DETAIL, {
                      state: data2[index],
                    });
                  }}
                  className="col-md-4"
                  key={index}
                >
                  <div className="position-relative features text-center mx-lg-4 px-md-1">
                    <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                      <FiHexagon className="hexagon" />
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <img
                          src={item.icon}
                          width="25px"
                          height="25px"
                          alt="icon"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <Link to="" className="fw-medium title text-dark fs-5">
                        {item.title}
                      </Link>
                      <p className="text-muted mt-3 mb-0">{item.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        {/* <div className="container-fluid bg-building-pic mt-100 mt-60">
          <div
            className=" opacity-25 position-absolute w-100 h-100 top-0 start-0"
            style={{
              backgroundImage: `url(${map})`,
              backgroundPosition: "center",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-3">Trusted by more than 10K users</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    A great plateform to buy, sell and rent your properties
                    without any agent or commisions.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={1548} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Investment
                  </h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={25} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">Awards</h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={9} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Profitability
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="container mt-100 mt-60">
          <ClientTwo />
        </div> */}
      </section>
      <Footer />
    </>
  );
}
