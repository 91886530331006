const RoutesEnums = {
  HOME: "/",
  INDEX_TWO: "/index-two",
  INDEX_THREE: "/index-three",
  INDEX_FOUR: "/index-four",
  INDEX_FIVE: "/index-five",
  INDEX_SIX: "/index-six",
  INDEX_SEVEN: "/index-seven",
  BUY: "/buy",
  SELL: "/sell",
  GRID: "/grid",
  GRID_SIDEBAR: "/grid-sidebar",
  LIST: "/list",
  LIST_SIDEBAR: "/list-sidebar",
  PROPERTY_DETAIL: "/property-detail",
  WANTED_PROPERTY_DETAIL: "/wanted-property-detail",
  PROPERTY_DETAIL_ID: "/property-detail/:id",
  WANTED_PROPERTY_DETAIL_ID: "/wanted-property-detail/:id",
  PROPERTY_DETAIL_TWO: "/property-detail-two",
  ABOUT_US: "/aboutus",
  SERVICES: "/services",
  PRICING: "/pricing",
  FAQS: "/faqs",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  BLOGS: "/insights-and-research",
  BLOG_SIDEBAR: "/blog-sidebar",
  BLOG: "/blog-detail",
  BLOG_DETAIL: "/blog-detail/:id",
  CONTACT_US: "/contactus",
  LOGIN: "/auth-login",
  SIGN_UP: "/auth-signup",
  RESET_PASSWORD: "/auth-reset-password",
  CREATE_BLOG: "/create-blog",
  COMING_SOON: "/comingsoon",
  MAINTENANCE: "/maintenance",
  ERROR: "/error",
  CAREERS: "/careers",
  CAREER_DETAIL: "/career-detail",
  SERVICE_DETAIL: "/service-detail",
  ADD_PROPERTY: "/add-property",
  WANTED_PROPERTY: "/add-wanted-property",
  INSIGHTS_AND_RESEARCH: "/tools",
};

export default RoutesEnums;
