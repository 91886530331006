import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { addDoc, collection, doc, updateDoc, increment, setDoc, getDoc, writeBatch } from "firebase/firestore"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ReCAPTCHA from "react-google-recaptcha";
import { db } from "../config";
import { storage } from "../config";
import { Progress } from "antd";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Spinner from "../common/loading-spinner";
import { toastUtil } from "../utils/toast.utils";
import RoutesEnums from "../enums/routes.enums";
import bg4 from "../assect/images/bg/04.jpg";
import "react-quill/dist/quill.snow.css";
import "../App.css";

export default function CreateBlog() {
  let navigate = useNavigate();
  const recaptcha = useRef();
  const [formData, setFormData] = useState({
    tag: "",
    title: "",
    description: "",
    author: "",
    date: "",
    image: "",
  });
  const MAX_IMG_SIZE = 5;
  const [percent, setPercent] = useState(0);
  const [showPercentBar, setShowPercentBar] = useState(false);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    let isAccess = localStorage.getItem("accessToken");
    if (!isAccess || isAccess == null || isAccess === "") {
      navigate(RoutesEnums.LOGIN);
    }
  }, []);

  const insertVideo = () => {
    try {
      const url = prompt("Enter the YouTube video URL:");
      if (url) {
        const videoEmbedHtml = `<iframe width="560" height="315" src="${url}" frameborder="0" allowfullscreen></iframe>`;
        const updatedHtml = descVal + videoEmbedHtml;
        setDescVal(updatedHtml);
      }
    } catch (error) {
      console.error("Error while inserting video:", error);
    }
  };

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["video"],
      ],
      handlers: {
        video: insertVideo,
      },
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleUpload = () => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      if (!formData.image) {
        alert("Please choose a file first!");
        reject("No file selected");
        return;
      }

      const storageRef = ref(storage, `/blogImages/${formData.imageName}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setShowPercentBar(true);
          setPercent(percent);
        },
        (err) => {
          console.error(err);
          setLoading(false);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              //console.log(url);
              resolve(url);
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        }
      );
    });
  };
  const [descVal, setDescVal] = useState("");
  const handleChange = (e) => {
    let { name, value, files } = e?.target;

    if (name === "image" && files.length > 0) {
      const fileSize = files.item(0).size;
      const fileSizeMb = fileSize / 1024 ** 2;

      if (fileSizeMb > MAX_IMG_SIZE) {
        alert(`Too big! Select an image under ${MAX_IMG_SIZE} MB!`);
        name = null;
        value = null;
        files = null;
        return false;
      }
      const imageName = files[0]?.name;

      setFormData((prevData) => ({
        ...prevData,
        imageName,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      setFormData({
        ...formData,
        tag: formData.tag.trim() + ",",
      });
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const captchaValue = recaptcha.current.getValue();
  //   if (!captchaValue) {
  //     toast.error("Please verify the Recaptcha!", toastUtil);
  //     return;
  //   }
  //   try {
  //     const imageUrl = await handleUpload();
  //     const docRef = await addDoc(collection(db, "blog"), {
  //       tag: formData.tag,
  //       title: formData.title,
  //       description: descVal,
  //       author: localStorage.getItem("userName"),
  //       date: formData.date,
  //       image: imageUrl,
  //     });

  //     //console.log("Document written with ID: ", docRef.id);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error.message, toastUtil);
  //     console.error("Error adding document: ", error);
  //   } finally {
  //     toast.success("Blog Created", toastUtil);
  //     setLoading(false);
  //     setFormData(null);
  //     navigate(RoutesEnums.BLOGS);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast.error("Please verify the Recaptcha!", toastUtil);
      return;
    }

    setLoading(true);
    
    try {
      const imageUrl = await handleUpload();

      // Add the blog post
      const docRef = await addDoc(collection(db, "blog"), {
        tag: formData.tag,
        title: formData.title,
        description: descVal,
        author: localStorage.getItem("userName"),
        date: formData.date,
        image: imageUrl,
      });

     
      const tags = formData.tag.split(',').map(tag => tag.trim());
      const tagsDocRef = doc(db, "popularTags", "tags");
      const tagsDoc = await getDoc(tagsDocRef);

      if (tagsDoc.exists()) {
        const currentTags = tagsDoc.data().tags || [];
        tags.forEach((tag) => {
          const tagObj = currentTags.find(t => t.tag === tag);
          if (tagObj) {
            tagObj.count += 1;
          } else {
            currentTags.push({ tag: tag, count: 1 });
          }
        });
        await setDoc(tagsDocRef, { tags: currentTags }, { merge: true });
      } else {
        const newTags = tags.map(tag => ({ tag: tag, count: 1 }));
        await setDoc(tagsDocRef, { tags: newTags });
      }

      toast.success("Blog Created", toastUtil);
      setFormData(null);
      navigate(RoutesEnums.BLOGS);

    } catch (error) {
      toast.error(error.message, toastUtil);
      console.error("Error adding document: ", error);
    } finally {
      setLoading(false);
    }
};




  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container end-section">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Create Blog
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">Piertop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Blog
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section pb-0">
        <div className="container end-section">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="p-4 rounded-3 shadow">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">
                          Tags <span className="text-danger">*</span>
                        </label>
                        <input
                          name="tag"
                          id="blog-tag"
                          type="text"
                          className="form-control"
                          placeholder="Tag :"
                          onChange={handleChange}
                          onKeyDown={handleKeyPress}
                          value={formData.tag}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="mb-3">
                        <label className="form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <input
                          name="title"
                          id="blog-title"
                          type="text"
                          className="form-control"
                          placeholder="Title :"
                          onChange={handleChange}
                          value={formData.title}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Date</label>
                        <input
                          name="date"
                          id="blog-date"
                          type="date"
                          className="form-control"
                          placeholder="Date :"
                          onChange={handleChange}
                          value={formData.date}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Description <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          // className="h-[500px]"
                          theme="snow"
                          value={descVal}
                          onChange={setDescVal}
                          // modules={modules}
                          // formats={formats}
                          required
                        />
                        {/* <textarea
                          name="description"
                          id="blog-description"
                          rows="4"
                          className="form-control"
                          placeholder="Description :"
                          onChange={handleChange}
                          value={formData.description}
                          required
                        ></textarea> */}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          name="image"
                          id="blog-image"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          className="form-control"
                          onChange={handleChange}
                          defaultValue={formData.image}
                          required
                        />
                        {showPercentBar && (
                          <span>
                            <Progress percent={percent} size="small" />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />

                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          id="blog-submit"
                          name="blog-submit"
                          className="btn btn-primary"
                        >
                          Create Blog
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
