export const toastUtil= {
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: true,
    pauseOnHover: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "light",                                             
  };
  