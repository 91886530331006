import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  startAfter,
  limit,
  orderBy,
  where,
} from "firebase/firestore";
import { db } from "../../config";

import bg3 from "../../assect/images/bg/03.jpg";
import Navbar from "../../components/navbar";
import Select from "react-select";
import Spinner from "../../common/loading-spinner";

import {
  FiDollarSign,
  FiHome,
  FiSearch,
  MdSquareFoot,
} from "../../assect/icons/vander";
import Footer from "../../components/footer";

const pageSize = 8; // Adjust the page size as needed

export default function List() {
  const categories = [
    { value: "1", label: "Featured" },
    { value: "2", label: "Wanted" },
  ];
  const area = [
    { value: "500", label: "500 sqft" },
    { value: "1000", label: "1000 sqft" },
    { value: "1500", label: "1500 sqft" },
    { value: "2000", label: "2000 sqft" },
    { value: "2500", label: "2500 sqft" },
    { value: "3000", label: "3000 sqft" },
    { value: "5000", label: "5000 sqft" },
    { value: "10000", label: "10000 sqft" },
  ];
  const price = [
    { value: "25000", label: "25,000$" },
    { value: "50000", label: "50,000$" },
    { value: "100000", label: "100,000$" },
    { value: "250000", label: "250,000$" },
    { value: "500000", label: "500,000$" },
    { value: "1000000", label: "1,000,000$" },
    { value: "100000000", label: "1,000,000+$" },
  ];

  const [loading, setLoading] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filterPageData, setFilterPageData] = useState([]);
  // const [currentPageData, setCurrentPageData] = useState([]);
  // const [lastDoc, setLastDoc] = useState(null);
  // const [hasMoreData, setHasMoreData] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [areaType, setAreaType] = useState("");
  const [priceType, setPriceType] = useState("");

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  /* useEffect(() => {
    // Effect to be executed when the propertyTypeRef value changes
    console.log("Coming in second useEffect!");
    setCurrentPageData([]);
    setLastDoc(null);
    handlePropertyTypeChange(propertyTypeRef.current);
  }, [propertyTypeRef.current]);*/

  const fetchData = async () => {
    // let q = query(
    //   collection(db, "properties"),
    //   orderBy("date"),
    //   limit(pageSize)
    // );

    // if (lastDoc) {
    //   // If lastDoc exists, modify the query to start after the last document
    //   q = query(
    //     collection(db, "properties"),
    //     orderBy("date"),
    //     startAfter(lastDoc),
    //     limit(pageSize)
    //   );
    // }
    let q = query(collection(db, "properties"), orderBy("date"));

    const querySnapshot = await getDocs(q);

    const newData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    //console.log("Data retrieved is: ", newData);

    // If the length of the fetched data is less than pageSize,
    // it means there are no more documents to fetch
    // if (newData.length < pageSize) {
    //   setHasMoreData(false);
    // }

    // Combine new data with existing data
    //setAllData((prevAllData) => [...prevAllData, ...newData]);
    // Set the data for the current page
    // setCurrentPageData((prevData) => [...prevData, ...newData]);
    setFilterPageData((prevData) => [...prevData, ...newData]);
    setOriginalData((prevData) => [...prevData, ...newData]);
    // Use a callback to ensure the latest value of lastDoc
    // setLastDoc(() => querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
    setLoadingSection(false);
  };

  const handleChange = async (selector, event) => {
    if (selector === "propertyType") {
      //console.log("Property type selected!", event);
      setPropertyType(event.label);
      setSearchTerm("");
      setAreaType("");
      setPriceType("");
      // setCurrentPageData([]);
      // setLastDoc(null);
      // await handlePropertyTypeChange(event.label);
      const filteredData = originalData.filter(
        (item) => item.propertyCatogery == event.label
      );
      setFilterPageData(filteredData);
    } else if (selector === "areaType") {
      //console.log("Area type selected!", event);
      setAreaType(event.value);
      setSearchTerm("");
      setPropertyType("");
      setPriceType("");
      // setCurrentPageData([]);
      // setLastDoc(null);
      // await handleAreaTypeChange(event.value);
      const filteredData = originalData.filter(
        (item) => item.building <= parseInt(event.value)
      );
      setFilterPageData(filteredData);
    } else if (selector === "priceType") {
      //console.log("Price type selected!", event);
      setPriceType(event.value);
      setSearchTerm("");
      setPropertyType("");
      setAreaType("");
      // setCurrentPageData([]);
      // setLastDoc(null);
      // await handlePriceTypeChange(event.value);
      const filteredData = originalData.filter(
        (item) => item.price <= parseInt(event.value)
      );
      setFilterPageData(filteredData);
    }
  };

  // useEffect(() => {
  //   console.log("now in property type useffect");
  //   console.log(propertyType);
  //   // Update the ref whenever propertyType changes
  // }, [propertyType]);

  // useEffect(() => {
  //   console.log("now in area type useffect");
  //   console.log(areaType);
  //   // Update the ref whenever propertyType changes
  // }, [areaType]);

  // useEffect(() => {
  //   console.log("now in price type useffect");
  //   console.log(priceType);
  //   // Update the ref whenever propertyType changes
  // }, [priceType]);

  // useEffect(() => {
  //   console.log("now in current page useffect");
  //   console.log(currentPageData);
  //   // Update the ref whenever propertyType changes
  // }, [currentPageData]);

  // const handlePropertyTypeChange = async (event) => {
  //   // console.log("Event passed is: ", event);
  //   // console.log("Current page data: ", currentPageData);
  //   // console.log("Last doc: ", lastDoc);

  //   // const q = query(
  //   //   collection(db, "properties"),
  //   //   where("propertyCatogery", "==", event), // Add this line for filtering by propertyCategory
  //   //   limit(pageSize)
  //   // );
  //   const q = query(
  //     collection(db, "properties"),
  //     where("propertyCatogery", "==", event) // Add this line for filtering by propertyCategory
  //   );

  //   /*if (lastDoc) {
  //     // If lastDoc exists, modify the query to start after the last document
  //     q = query(
  //       collection(db, "properties"),
  //       where("propertyCatogery", "==", event), // Add this line for filtering by propertyCategory
  //       // orderBy("date"),
  //       startAfter(lastDoc),
  //       limit(pageSize)
  //     );
  //   }*/

  //   const querySnapshot = await getDocs(q);

  //   const newData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   console.log("Data retrieved is: ", newData);

  //   // If the length of the fetched data is less than pageSize,
  //   // it means there are no more documents to fetch
  //   if (newData.length < pageSize) {
  //     setHasMoreData(false);
  //   }

  //   // Combine new data with existing data
  //   //setAllData((prevAllData) => [...prevAllData, ...newData]);
  //   // Set the data for the current page
  //   setCurrentPageData((prevData) => [...prevData, ...newData]);
  //   setFilterPageData(newData);
  //   // Use a callback to ensure the latest value of lastDoc
  //   setLastDoc(() => querySnapshot.docs[querySnapshot.docs.length - 1]);
  //   setLoading(false);
  //   setLoadingSection(false);
  // };

  // const handleAreaTypeChange = async (event) => {
  //   // console.log("Event passed is: ", event);
  //   // console.log("Current page data: ", currentPageData);
  //   // console.log("Last doc: ", lastDoc);

  //   // const q = query(
  //   //   collection(db, "properties"),
  //   //   where("building", "<=", parseInt(event)), // Add this line for filtering by area
  //   //   limit(pageSize)
  //   // );
  //   const q = query(
  //     collection(db, "properties"),
  //     where("building", "<=", parseInt(event)) // Add this line for filtering by area
  //   );

  //   /*if (lastDoc) {
  //     // If lastDoc exists, modify the query to start after the last document
  //     q = query(
  //       collection(db, "properties"),
  //       where("propertyCatogery", "==", event), // Add this line for filtering by propertyCategory
  //       // orderBy("date"),
  //       startAfter(lastDoc),
  //       limit(pageSize)
  //     );
  //   }*/

  //   const querySnapshot = await getDocs(q);

  //   const newData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   console.log("Data retrieved is: ", newData);

  //   // If the length of the fetched data is less than pageSize,
  //   // it means there are no more documents to fetch
  //   if (newData.length < pageSize) {
  //     setHasMoreData(false);
  //   }

  //   // Set the data for the current page
  //   setCurrentPageData((prevData) => [...prevData, ...newData]);
  //   setFilterPageData(newData);
  //   // Use a callback to ensure the latest value of lastDoc
  //   setLastDoc(() => querySnapshot.docs[querySnapshot.docs.length - 1]);
  //   setLoading(false);
  //   setLoadingSection(false);
  // };

  // const handlePriceTypeChange = async (event) => {
  //   // console.log("Event passed is: ", event);
  //   // console.log("Current page data: ", currentPageData);
  //   // console.log("Last doc: ", lastDoc);

  //   // const q = query(
  //   //   collection(db, "properties"),
  //   //   where("price", "<=", parseInt(event)), // Add this line for filtering by area
  //   //   limit(pageSize)
  //   // );
  //   const q = query(
  //     collection(db, "properties"),
  //     where("price", "<=", parseInt(event)) // Add this line for filtering by area
  //   );

  //   /*if (lastDoc) {
  //     // If lastDoc exists, modify the query to start after the last document
  //     q = query(
  //       collection(db, "properties"),
  //       where("propertyCatogery", "==", event), // Add this line for filtering by propertyCategory
  //       // orderBy("date"),
  //       startAfter(lastDoc),
  //       limit(pageSize)
  //     );
  //   }*/

  //   const querySnapshot = await getDocs(q);

  //   const newData = querySnapshot.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   console.log("Data retrieved is: ", newData);

  //   // If the length of the fetched data is less than pageSize,
  //   // it means there are no more documents to fetch
  //   if (newData.length < pageSize) {
  //     setHasMoreData(false);
  //   }

  //   // Set the data for the current page
  //   setCurrentPageData((prevData) => [...prevData, ...newData]);
  //   setFilterPageData(newData);
  //   // Use a callback to ensure the latest value of lastDoc
  //   setLastDoc(() => querySnapshot.docs[querySnapshot.docs.length - 1]);
  //   setLoading(false);
  //   setLoadingSection(false);
  // };

  const handleSearch = (event) => {
    setPropertyType("");
    setAreaType("");
    setPriceType("");
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm == "") {
      // setCurrentPageData(originalData);
      setFilterPageData(originalData);
    } else {
      // Filter data based on the search term
      const filteredData = originalData.filter((item) =>
        item.address.toLowerCase().includes(searchTerm)
      );
      // setCurrentPageData(filteredData);
      setFilterPageData(filteredData);
    }
    setSearchTerm(searchTerm);
  };

  return (
    <>
      {loading && <Spinner />}
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Property Listing
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="features-absolute">
                <div className="card border-0 bg-white shadow mt-5">
                  <form className="card-body text-start">
                    <div className="registration-form text-dark text-start">
                      <div className="row g-lg-0">
                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="mb-lg-0 mb-3">
                            <div className="filter-search-form position-relative filter-border">
                              <FiSearch className="fea icon-ex-md icons" />
                              <input
                                name="name"
                                type="text"
                                id="job-keyword"
                                className="form-control filter-input-box bg-light border-0"
                                placeholder="Search your keaywords"
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-6 col-12">
                          <div className="mb-lg-0 mb-3">
                            <div className="filter-search-form position-relative filter-border bg-light">
                              <FiHome className="fea icon-ex-md icons" />
                              <Select
                                className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                options={categories}
                                isSearchable={false}
                                value={
                                  propertyType
                                    ? categories.find(
                                        (option) =>
                                          option.label === propertyType
                                      )
                                    : null
                                }
                                onChange={(event) =>
                                  handleChange("propertyType", event)
                                }
                                placeholder={<div>Property Type</div>}
                              />
                            </div>
                          </div>
                        </div> */}

                        <div className="col-lg-4 col-md-4 col-12">
                          <div className="mb-lg-0 mb-3">
                            <div className="filter-search-form position-relative filter-border bg-light">
                              <MdSquareFoot className="fea icon-ex-md icons" />
                              <Select
                                className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                options={area}
                                isSearchable={false}
                                value={
                                  areaType
                                    ? area.find(
                                        (option) => option.value === areaType
                                      )
                                    : null
                                }
                                onChange={(event) =>
                                  handleChange("areaType", event)
                                }
                                placeholder={<div>Area</div>}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12">
                          <div>
                            <div className="filter-search-form position-relative filter-border bg-light">
                              <FiDollarSign className="fea icon-ex-md icons" />
                              <Select
                                className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                                options={price}
                                isSearchable={false}
                                value={
                                  priceType
                                    ? price.find(
                                        (option) => option.value === priceType
                                      )
                                    : null
                                }
                                onChange={(event) =>
                                  handleChange("priceType", event)
                                }
                                placeholder={<div>Price</div>}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-4 mt-0">
            {filterPageData.length != 0 ? (
              filterPageData.map((item, index) => {
                return (
                  <div className="col-lg-6 col-12" key={index}>
                    <div className="card property property-list border-0 shadow position-relative overflow-hidden rounded-3">
                      <div className="d-md-flex">
                        <div
                          className="property-image position-relative overflow-hidden shadow flex-md-shrink-0 rounded-3 m-2"
                          style={{
                            aspectRatio: "9/16",
                          }}
                        >
                          <img
                            src={item.image}
                            className="img-fluid h-100 w-100"
                            alt=""
                          />
                        </div>
                        <div className="card-body content p-3">
                          <Link
                            to={`/property-detail/${item.id}`}
                            className="title fs-5 text-dark fw-medium"
                          >
                            {item.address}
                          </Link>

                          <ul className="list-unstyled mt-3 py-3 border-top border-bottom d-flex align-items-center justify-content-between">
                            <li className="d-flex align-items-center me-3">
                              <i className="mdi mdi-arrow-expand-all fs-5 me-2 text-primary"></i>
                              <span className="text-muted">
                                {item.building} ± sq ft
                              </span>
                            </li>

                            <li className="d-flex align-items-center me-3">
                              <i className="mdi mdi-currency-usd fs-5 me-2 text-primary"></i>
                              <span className="text-muted">
                                Price $ {item.price}
                              </span>
                            </li>
                          </ul>

                          <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                            <li className="list-inline-item mb-0">
                              <span className="text-muted">Agent</span>
                              <p className="fw-medium mb-0">{item.agent}</p>
                            </li>
                            <li className="list-inline-item mb-0">
                              <span className="text-muted">Phone</span>
                              <p className="fw-medium mb-0">{item.phone}</p>
                            </li>
                          </ul>

                          <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                            <li className="list-inline-item mb-0">
                              <span className="text-muted">Category</span>
                              <p className="fw-medium mb-0">
                                {item.propertyCatogery}
                              </p>
                            </li>
                            <li className="list-inline-item mb-0">
                              <span className="text-muted">Type</span>
                              <p className="fw-medium mb-0">
                                {item.propertyType}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No Properties Found!</div>
            )}
          </div>

          <div className="row">
            <div className="col mt-4 pt-2 center-btn">
              {/* <ul className="pagination justify-content-center mb-0">
                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Previous">
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-left fs-6"></i>
                    </span>
                  </Link>
                </li>

                {renderPaginationLinks()}

                <li className="page-item">
                  <Link className="page-link" to="#" aria-label="Next">
                    <span aria-hidden="true">
                      <i className="mdi mdi-chevron-right fs-6"></i>
                    </span>
                  </Link>
                </li>
              </ul> */}

              {/* {hasMoreData && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setLoadingSection(true);
                    fetchData();
                  }}
                >
                  Load More
                </button>
              )} */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
